import { useCubeTexture, useGLTF, useTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import tinycolor  from 'tinycolor2';

interface MyProps {
  color: string;
}

export const SkyboxComponent = (props: MyProps) => {
  return (<mesh
    position={[0, 0.4, 0]}
    >
        <sphereGeometry attach="geometry" args={[3, 20, 20]}/>
        <meshBasicMaterial attach="material" color={tinycolor(props.color).lighten(20).toRgbString()} side={THREE.BackSide} />
    </mesh>);
};
