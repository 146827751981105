import { Sky } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { RootState } from "@react-three/fiber/dist/declarations/src/core/store";
import React, { useState } from "react";
import { THREECamera } from "../Camera/camera.three.component";
import * as THREE from 'three';

interface Props {
  children?: JSX.Element[];
}

export const SceneComponent = (props: Props) => {
    
const [camera, setCamera] = useState<THREE.Camera>();
const [dom, setDom] = useState<HTMLElement>();

  const onCreated = ({ gl, camera, scene }: RootState) => {
    setCamera(camera);
    setDom(gl.domElement);

    // gl.shadowMap.type = THREE.PCFShadowMap;
    gl.physicallyCorrectLights = true;
  };

  return (
    <Canvas
      gl={{ alpha: false, autoClearDepth: false }}
      camera={{ fov: 30, position: [0, 3, 5] }}
      onCreated={onCreated}
      style={{position: 'fixed', zIndex: 1, pointerEvents: 'auto'}}
      shadows
    >
      <Sky
        distance={450000} // Camera distance (default=450000)
        sunPosition={[0, 1, 0]} // Sun position normal (defaults to inclination and azimuth if not set)
        inclination={0} // Sun elevation angle from 0 to 1 (default=0)
        azimuth={0.25} // Sun rotation around the Y axis from 0 to 1 (default=0.25)
      />
      {/* <EffectComposer>
                <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} />
                <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} />
                <Noise opacity={0.02} />
                <Vignette eskil={false} offset={0.1} darkness={1.1} />
            </EffectComposer> */}
      <directionalLight intensity={1.5}></directionalLight>
      <spotLight
        intensity={2}
        position={[2, 8, 2]}
        castShadow={true}
        shadow-mapSize-width={1024 * 4}
        shadow-mapSize-height={1024 * 4}
        shadow-bias={0}
        shadow-camera-far={10}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      ></spotLight>
      <spotLight intensity={2} position={[-1, 2, 3]}></spotLight>
      <spotLight intensity={2} position={[1, 2, 3]}></spotLight>
      <spotLight intensity={2} position={[-1, 2, -3]}></spotLight>
      <spotLight intensity={2} position={[1, 2, -3]}></spotLight>
    
      {props.children}
      {/* <mesh
        receiveShadow
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.4, 0]}
      >
        <planeBufferGeometry attach="geometry" args={[500, 500, 50, 50]} />
        <shadowMaterial attach="material" color="#FFF" />
      </mesh> */}
    </Canvas>
  );
};
