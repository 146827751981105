import { useFBX, useTexture } from '@react-three/drei';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import REACT, { Suspense } from 'react';
import * as THREE from 'three';
import { MeshBasicMaterial } from 'three';

interface Props {
    url: string;
}

export const ThreeSixtyComponent = (props: Props) => {
    
    const texture = useTexture(props.url);
    const three = useThree();

    return <mesh position={[0, 0.4, 0]} scale={[-1, 1, 1]} rotation={[0, -Math.PI / 2, 0]}>
        <sphereGeometry attach="geometry" args={[3, 20, 20]}/>
        <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide} />
    </mesh>;
};