import React, { useEffect, useState } from "react";

interface MyProps {
  onHide: () => void;
  productName: string;
  show: boolean;
}

enum EmailSubmittionState {
  Idle,
  FormError,
  Sending,
  ErrorResponse,
  SuccessResponse
}

export const EmailComponentUI = (props: MyProps) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailState, setEmailState] = useState(EmailSubmittionState.Idle);
  const [emailEndPoint, setEmailEndPoint] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // const emailEndPoint = "https://stage.modernaproducts.com/yondr-mail/customer-contact";
  // const emailEndPoint = "https://www.modernaproducts.com/yondr-mail/customer-contact";

  useEffect(() => {
      fetch("./config.json").then(r => r.json()).then(r => setEmailEndPoint(r.emailEndpoint))
  }, [])

  const submit = () => {
    setEmailState(EmailSubmittionState.Sending);
    if(!name || !email || !message) {
      setEmailState(EmailSubmittionState.FormError);
    }
    else {
      fetch(emailEndPoint as string, { 
        method: 'POST',
        headers: { 
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          'email': email,
          'subject': `Contact form for ${props.productName} from ${name}`,
          'message': message
        })
       })
       .then(r => r.json())
       .then(r => {
         if(r && r.status == 'success') {
          setEmailState(EmailSubmittionState.SuccessResponse);
         }
         else {
           setEmailState(EmailSubmittionState.ErrorResponse);

           if(r && r.message) {
            setErrorMessages(r.message);
           } else {
             setErrorMessages(["An unexpected error occured. Please try again later."])
           }
         }
       })
       .catch(e => {
          setEmailState(EmailSubmittionState.ErrorResponse);
          setErrorMessages(["An unexpected error occured. Please try again later."])
       });
    }
  };

  useEffect(() => {
    setName('');
    setEmail('');
    setMessage('');
    setEmailState(EmailSubmittionState.Idle);
  }, [props.show]);

  if(!props.show || !emailEndPoint) return null;

  return (
    <div
      style={{ transform: props.show ? "" : "", zIndex: 99, width: '100vw', height: '100vh', position: 'fixed', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <div className="email-container d-flex flex-column justify-content-center align-items-center p-4" style={{position: 'relative'}}>
        <img style={{position: 'absolute', right: '10px', top: '10px'}} onClick={() => props.onHide()} className="pointer" src="./media/images/close-button.svg"/>
        <h2>Request more info!</h2>
        <input className={emailState == EmailSubmittionState.FormError && !name ? 'error' : ''} value={name} required name="name" placeholder="name" onChange={(e) => setName(e.target.value)} />
        <input className={emailState == EmailSubmittionState.FormError && !email ? 'error' : ''}  value={email} required name="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} />
        <textarea className={emailState == EmailSubmittionState.FormError && !message ? 'error' : ''}  value={message} required rows={5} placeholder="message" onChange={(e) => setMessage(e.target.value)} />
        {(emailState == EmailSubmittionState.Idle || emailState == EmailSubmittionState.FormError) && <button onClick={() => submit()} className="submit-button pointer">Submit</button> }
        {emailState == EmailSubmittionState.SuccessResponse && <h2>Your info is being processed. Thank you for reaching out to us!</h2> }
        {emailState == EmailSubmittionState.ErrorResponse && errorMessages.map(error => <h2 style={{color: 'red'}}>{errorMessages}</h2>) }
        {emailState == EmailSubmittionState.Sending && <h2>Sending your request...</h2> }
      </div>
    </div>
  );
};
