import * as THREE from "three";

export interface Cluster {
    title: string;
    bg: string;
    parallaxVideoUrl: string;
    fadeInLeftVideoUrl: string;
    fadeInRightVideoUrl: string;
    fadeOutLeftVideoUrl: string;
    fadeOutRightVideoUrl: string;
    products: Dot[];
}

export interface Dot {
  x: number;
  y: number;
  parallaxX: number;
  parallaxY: number;
  models: Model[];
}

export interface CustomColor {
  materialName: string;
  metallic?: number;
  roughness?: number;
  color?: string;
}

export interface Model {
    key: string;
    url: string;
    scale: number;
    zoom: THREE.Vector3;
    animations: Animation[];
    colorBodyNames: string[];
    focusPoint: THREE.Vector3;
    customColors?: CustomColor[];
    animatedMaterials?: AnimatedMaterials[];
}

export interface AnimatedMaterials {
    objectName: string;
    texture: string;
    color: string;
    x: number;
    y: number;
}

export interface Animation {
  labelPosition: THREE.Vector3;
  animationStartTime: number;
  animationDuration: number;
}

export const clusters: Cluster[] = [
  {
    title: "+98% Recycled Plastic Collection",
    bg: './media/360/room_98_360_blurred.jpg',
    parallaxVideoUrl: "./media/video/room-parallax/98_PARALAX.mp4",
    fadeInLeftVideoUrl: "./media/video/room-transition/98_OUT_REV.mp4",
    fadeInRightVideoUrl: "./media/video/room-transition/98_IN.mp4",
    fadeOutLeftVideoUrl: "./media/video/room-transition/98_OUT.mp4",
    fadeOutRightVideoUrl: "./media/video/room-transition/98_IN_REV.mp4",
    products: [
      {
        x: .09,
        y: .6,
        parallaxX: -0.02,
        parallaxY: 0.01,
        models: [
          {
            key: 'MOD-AA50',
            focusPoint: new THREE.Vector3(0, 0.1, 0),
            url: './models/Maryloo with Rim Large.glb',
            scale: .01,
            zoom: new THREE.Vector3(.97, .45, .866), 
            animations: [
            ],
            colorBodyNames: ['MARYLOO_AA50'],
            customColors: [
              // {
              //   materialName: 'Warm Grey',
              //   color: 'rgb(175, 169, 159)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              // {
              //   materialName: 'Spicy Coral',
              //   color: 'rgb(101, 221, 230)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              // {
              //   materialName: 'Aquarelle',
              //   color: 'rgb(101, 221, 230)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              // {
              //   materialName: 'White',
              //   color: 'rgb(255, 255, 255)',
              //   metallic: 0.7,
              //   roughness: 0.6
              // }
            ]
          }]
      },
      {
        x: .135,
        y: .57,
        parallaxX: -0.02,
        parallaxY: 0.001,
        models: [
          {
            key: 'MOD-AI44',
            focusPoint: new THREE.Vector3(0, 0.03, 0),
            url: './models/Handy.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.47, .27, 0.55), 
            animations: [

            ],
            colorBodyNames: ['Handy', 'handle'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          },
          
          {
            key: 'MOD-AI40',
            focusPoint: new THREE.Vector3(0, 0.03, 0),
            url: './models/Handy Max.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.47, .27, 0.55), 
            animations: [

            ],
            colorBodyNames: ['Handy', 'handle'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          }
        ]
      },
      {
        x: .37,
        y: .6,
        parallaxX: .005,
        parallaxY: 0.00,
        models: [
          {
            key: 'MOD-C370',
            focusPoint: new THREE.Vector3(0, .2, 0),
            url: './models/Smart Cat.glb',
            scale: .01,
            zoom: new THREE.Vector3(1.01, 0.5, 1.06), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .37, .3),
                animationStartTime: 0,
                animationDuration: 100
              }
            ],
            colorBodyNames: ['Smart_Cat'],
            customColors: [
               {
                 materialName: 'Warm Grey',
                 color: 'rgb(175, 169, 159)',
                 metallic: 0.5,
                 roughness: 0.6
               },
               {
                materialName: 'Spicy Coral',
                color: 'rgb(233, 43, 43)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Aquarelle',
               color: 'rgb(101, 221, 230)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'White_Shiny',
              color: 'rgb(255, 255, 255)',
              metallic: 0.7,
              roughness: .6
            },
            {
             materialName: 'Clear Textured Plastic',
             color: 'rgb(255, 255, 255)',
             metallic: 1,
             roughness: .2
           }
            ]
          }]
      },
      {
        x: .69,
        y: .56,
        parallaxX: -0.03,
        parallaxY: -0.003,
        models: [
          {
            key: 'MOD-T201',
            focusPoint: new THREE.Vector3(0, .15, 0),
            url: './models/Road Runner Metal Door Medium.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.77, 0.55, 1.2), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .38, 0),
                animationStartTime: 0,
                animationDuration: 100
              },
              {
                labelPosition: new THREE.Vector3(.1, .18, .27),
                animationStartTime: 100,
                animationDuration: 230
              }
            ],
            colorBodyNames: ['onderschelp'],
            customColors: [
              // {
              //   materialName: 'Blue Berry',
              //   color: 'rgb(66, 78, 87)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              // {
              //   materialName: 'Dark Grey',
              //   color: 'rgb(66, 78, 87)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              // {
              //   materialName: 'Aquarelle',
              //   color: 'rgb(101, 221, 230)',
              //   metallic: 0.5,
              //   roughness: 0.6
              // },
              {
                materialName: 'dove_grey',
                color: 'rgb(88, 88, 88)',
                metallic: 0.3,
                roughness: 1
              },
              {
                materialName: 'tit_grey',
                color: 'rgb(180, 206, 228)',
                metallic: 0.7,
                roughness: 0.6
              },
              {
                materialName: 'ALU',
                color: 'rgb(156, 156, 156)',
                metallic: 1,
                roughness: .1
              }
            ]
          },
          {
            key: 'MOD-T101',
            focusPoint: new THREE.Vector3(0, .15, 0),
            url: './models/Road Runner Metal Door Small.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.77, 0.55, 1.2), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .33, 0),
                animationStartTime: 0,
                animationDuration: 100
              },
              {
                labelPosition: new THREE.Vector3(.1, .15, .27),
                animationStartTime: 100,
                animationDuration: 230
              }
            ],
            colorBodyNames: ['onderschelp']
          },
          {
            key: 'MOD-T100',
            focusPoint: new THREE.Vector3(0, .15, 0),
            url: './models/Road Runner Plastic Door Small.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.77, 0.55, 1.2), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .33, 0),
                animationStartTime: 0,
                animationDuration: 100
              },
              {
                labelPosition: new THREE.Vector3(.1, .15, .25),
                animationStartTime: 100,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['onderschelp']
          },
          {
            key: 'MOD-T203',
            focusPoint: new THREE.Vector3(0, .15, 0),
            url: './models/Road Runner Spring Lock Door Medium.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.77, 0.55, 1.2), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .37, 0),
                animationStartTime: 0,
                animationDuration: 100
              },
              {
                labelPosition: new THREE.Vector3(.1, .17, .3),
                animationStartTime: 100,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['onderschelp']
          },
          {
            key: 'MOD-T103',
            focusPoint: new THREE.Vector3(0, .15, 0),
            url: './models/Road Runner Spring Lock Door Small.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.77, 0.55, 1.2), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .33, 0),
                animationStartTime: 0,
                animationDuration: 100
              },
              {
                labelPosition: new THREE.Vector3(.1, .15, .25),
                animationStartTime: 100,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['onderschelp']
          }
        ]
      },
      {
        x: .705,
        y: .69,
        parallaxX: 0.02,
        parallaxY: -0.005,
        models: [
          {
            key: 'MOD-BA10',
            focusPoint: new THREE.Vector3(0, 0, 0),
            url: './models/Double Gusto X-Small.glb',
            scale: .01, 
            zoom: new THREE.Vector3(1.22, .66, 1.37), 
            animations: [

            ],
            colorBodyNames: ['GUSTOBOWL'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          },  
          {
            key: 'MOD-BA04',
            focusPoint: new THREE.Vector3(0, 0, 0),
            url: './models/Gusto Large.glb',
            scale: .01,             
            zoom: new THREE.Vector3(1.22, .66, 1.37), 
            animations: [

            ],
            colorBodyNames: ['GUSTOBOWL'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          },
          {
            key: 'MOD-BA02',
            focusPoint: new THREE.Vector3(0, 0, 0),
            url: './models/Gusto Medium.glb',
            scale: .01, 
            zoom: new THREE.Vector3(1.22, .66, 1.37), 
            animations: [

            ],
            colorBodyNames: ['GUSTOBOWL'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          },
          {
            key: 'MOD-BA01',
            focusPoint: new THREE.Vector3(0, 0, 0),
            url: './models/Gusto Small.glb',
            scale: .01, 
            zoom: new THREE.Vector3(1.22, .66, 1.37), 
            animations: [

            ],
            colorBodyNames: ['GUSTOBOWL'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          },
          {
            key: 'MOD-BA00',
            focusPoint: new THREE.Vector3(0, 0, 0),
            url: './models/Gusto X-Small.glb',
            scale: .01, 
            zoom: new THREE.Vector3(1.22, .66, 1.37), 
            animations: [

            ],
            colorBodyNames: ['GUSTOBOWL'],
            customColors: [
              {
                materialName: 'Warm Grey',
                color: 'rgb(175, 169, 159)',
                metallic: 0.5,
                roughness: 0.6
              },
              {
               materialName: 'Spicy Coral',
               color: 'rgb(233, 43, 43)',
               metallic: 0.5,
               roughness: 0.6
             },
             {
              materialName: 'Aquarelle',
              color: 'rgb(101, 221, 230)',
              metallic: 0.5,
              roughness: 0.6
            },
            {
             materialName: 'White_Shiny',
             color: 'rgb(255, 255, 255)',
             metallic: 0.7,
             roughness: .6
           },
           {
            materialName: 'Clear Textured Plastic',
            color: 'rgb(255, 255, 255)',
            metallic: 1,
            roughness: .2
          }
            ]
          }
        ]
      },
      ]
  },
  {
    title: "Casetta Collection",
    bg: './media/360/room_casetta_360_blurred.jpg',
    parallaxVideoUrl: "./media/video/room-parallax/CASETTA_PARALAX.mp4",
    fadeInLeftVideoUrl: "./media/video/room-transition/CASETTA_OUT_REV.mp4",
    fadeInRightVideoUrl: "./media/video/room-transition/CASETTA_IN.mp4",
    fadeOutLeftVideoUrl: "./media/video/room-transition/CASETTA_OUT.mp4",
    fadeOutRightVideoUrl: "./media/video/room-transition/CASETTA_IN_REV.mp4",
    products: [
      { 
        x: .52, 
        y: .45,
        parallaxX: .01,
        parallaxY: 0,
        models: [
          {
            key: 'MOD-BL73',
            focusPoint: new THREE.Vector3(0, 0.25, 0),
            url: './models/Casetta - Soft White - Camelia.glb',
            zoom: new THREE.Vector3(1.4, 0.87, 1.98), 
            scale: 1,
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .5, 0),
                animationStartTime: 0,
                animationDuration: 130
              },
              {
                labelPosition: new THREE.Vector3(.5, .4, 0),
                animationStartTime: 130,
                animationDuration: 150
              }
            ],
            colorBodyNames: [],
            customColors: [
              {
                materialName: 'soft_white_textured',
                metallic: 1,
                roughness: 0.65,
                color: 'rgb(255, 255, 255)'
              },
              {
                materialName: 'warm_grey_shiny',
                metallic: .8,
                roughness: 0.45,
                color: 'rgb(161, 161, 140)'
              },
              {
                materialName: 'warm_grey_textured',
                metallic: 0.55,
                roughness: 1,
                color: 'rgb(161, 161, 140)'
              },
              {
                materialName: 'PRINT',
                metallic: 0.3,
                roughness: .6,
                color: 'rgb(204, 204, 255)'
              },
              {
                materialName: 'FLOOR',
                metallic: 0.1,
                roughness: .5,
                color: 'rgb(255, 255, 255)'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "Odyssey Collection",
    bg: './media/360/room_odessey_360_blurred.jpg',
    parallaxVideoUrl: "./media/video/room-parallax/ODESSEY_PARALAX.mp4",
    fadeInLeftVideoUrl: "./media/video/room-transition/ODESSEY_OUT_REV.mp4",
    fadeInRightVideoUrl: "./media/video/room-transition/ODESSEY_IN.mp4",
    fadeOutLeftVideoUrl: "./media/video/room-transition/ODESSEY_OUT.mp4",
    fadeOutRightVideoUrl: "./media/video/room-transition/ODESSEY_IN_REV.mp4",
    products: [
      {
        x: .57, 
        y: .6,
        parallaxX: .01,
        parallaxY: 0,
        models: [
          {
            key: 'MOD-AZ60',
            focusPoint: new THREE.Vector3(0, 0.15, 0),
            url: './models/Odyssey X-Small.glb',
            scale: .01,
            zoom: new THREE.Vector3(0.84, 0.72, 1.29), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .4, 0),
                animationStartTime: 0,
                animationDuration: 450
              },
              {
                labelPosition: new THREE.Vector3(.08, .17, .26),
                animationStartTime: 450,
                animationDuration: 260
              },
              {
                labelPosition: new THREE.Vector3(.15, .33, 0),
                animationStartTime: 450 + 260,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['hood'],
            customColors: [
              {
                materialName: 'DARK_GREY',
                color: 'rgb(74, 75, 80)',
                metallic: 0.5,
                roughness: .5
              },    
              {
                materialName: 'OLIVE',
                color: 'rgb(149, 154, 147)',
                metallic: 0.75,
                roughness: .5
              },    
              {
                materialName: 'YELLOW',
                color: 'rgb(255, 200, 0)',
                metallic: 0.55,
                roughness: 0.9
              },    
              {
                materialName: 'ALU',
                color: 'rgb(156, 156, 156)',
                metallic: 1,
                roughness: 0.1
              },    
              {
                materialName: 'FLOOR',
                color: 'rgb(255, 255, 255)',
                metallic: 0.2,
                roughness: 0.6
              }
            ]
          },
          {
            key: 'MOD-AZ61',
            url: './models/Odyssey Small.glb',
            focusPoint: new THREE.Vector3(0, 0.18, 0),
            scale: .01,
            zoom: new THREE.Vector3(0.84, 0.72, 1.29), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .45, 0),
                animationStartTime: 0,
                animationDuration: 450
              },
              {
                labelPosition: new THREE.Vector3(.09, .185, .295),
                animationStartTime: 450,
                animationDuration: 260
              },
              {
                labelPosition: new THREE.Vector3(.15, .37, 0),
                animationStartTime: 450 + 260,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['hood'],
            customColors: [
              {
                materialName: 'DARK_GREY',
                color: 'rgb(74, 75, 80)',
                metallic: 0.5,
                roughness: .5
              },    
              {
                materialName: 'OLIVE',
                color: 'rgb(149, 154, 147)',
                metallic: 0.75,
                roughness: .5
              },    
              {
                materialName: 'YELLOW',
                color: 'rgb(255, 200, 0)',
                metallic: 0.55,
                roughness: 0.9
              },    
              {
                materialName: 'ALU',
                color: 'rgb(156, 156, 156)',
                metallic: 1,
                roughness: 0.1
              },    
              {
                materialName: 'FLOOR',
                color: 'rgb(255, 255, 255)',
                metallic: 0.2,
                roughness: 0.6
              }
            ]
          },
          {
            key: 'MOD-AZ62',
            url: './models/Odyssey Medium.glb',
            focusPoint: new THREE.Vector3(0, 0.19, 0),
            scale: .01,
            zoom: new THREE.Vector3(0.84, 0.72, 1.29), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .47, 0),
                animationStartTime: 0,
                animationDuration: 450
              },
              {
                labelPosition: new THREE.Vector3(.11, .2, .32),
                animationStartTime: 450,
                animationDuration: 260
              },
              {
                labelPosition: new THREE.Vector3(.17, .39, 0),
                animationStartTime: 450 + 260,
                animationDuration: 200
              }
            ],
            colorBodyNames: ['hood'],
            customColors: [
              {
                materialName: 'DARK_GREY',
                color: 'rgb(74, 75, 80)',
                metallic: 0.5,
                roughness: .5
              },    
              {
                materialName: 'OLIVE',
                color: 'rgb(149, 154, 147)',
                metallic: 0.75,
                roughness: .5
              },    
              {
                materialName: 'YELLOW',
                color: 'rgb(255, 200, 0)',
                metallic: 0.55,
                roughness: 0.9
              },    
              {
                materialName: 'ALU',
                color: 'rgb(156, 156, 156)',
                metallic: 1,
                roughness: 0.1
              },    
              {
                materialName: 'FLOOR',
                color: 'rgb(255, 255, 255)',
                metallic: 0.2,
                roughness: 0.6
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "Skybar Collection",
    bg: './media/360/room_skybar_360_blurred.jpg',
    parallaxVideoUrl: "./media/video/room-parallax/SKYBAR_PARALAX.mp4",
    fadeInLeftVideoUrl: "./media/video/room-transition/SKYBAR_OUT_REV.mp4",
    fadeInRightVideoUrl: "./media/video/room-transition/SKYBAR_IN.mp4",
    fadeOutLeftVideoUrl: "./media/video/room-transition/SKYBAR_OUT.mp4",
    fadeOutRightVideoUrl: "./media/video/room-transition/SKYBAR_IN_REV.mp4",
    products: [
      {
        x: .47, 
        y: .58,
        parallaxX: .001,
        parallaxY: 0,
        models: [
          {
            url: './models/Skybar Small.glb',
            focusPoint: new THREE.Vector3(0, 0.1, 0),
            key: 'MOD-AK50',
            scale: .01,
            zoom: new THREE.Vector3(0.92, 0.48, 1.11), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .3, 0),
                animationStartTime: 0,
                animationDuration: 160
              }
            ],
            colorBodyNames: [], 
            customColors: [
              {
                materialName: 'ALU',
                color: 'rgb(200, 200, 200)',
                metallic: 1,
                roughness: 0.1
              },
              {
                materialName: 'Hard_Rough_Plastic',
                color: 'rgb(240, 229, 214)',
                metallic: 0,
                roughness: 0.3
              },
              {
                materialName: 'FLOOR',
                color: 'rgb(255, 238, 224)',
                metallic: 0.15,
                roughness: 0.4
              }
            ]
          },
          {
            url: './models/Skybar Medium.glb',
            focusPoint: new THREE.Vector3(0, 0.15, 0),
            key: 'MOD-AK51',
            scale: .01,
            zoom: new THREE.Vector3(1.08, 0.7, 1.33), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .4, 0),
                animationStartTime: 0,
                animationDuration: 160
              }
            ],
            colorBodyNames: []
          },
          {
            url: './models/Skybar Large.glb',
            focusPoint: new THREE.Vector3(0, 0.2, 0),
            key: 'MOD-AK52',
            scale: .01,
            zoom: new THREE.Vector3(1.3, 0.88, 1.5), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .5, 0),
                animationStartTime: 0,
                animationDuration: 160
              }
            ],
            colorBodyNames: []
          }
        ]
      }
    ]
  },
  {
    title: "Lotus Collection",
    bg: './media/360/room_lotus_360_blurred.jpg',
    parallaxVideoUrl: "./media/video/room-parallax/LOTUS_PARALAX.mp4",
    fadeInLeftVideoUrl: "./media/video/room-transition/LOTUS_OUT_REV.mp4",
    fadeInRightVideoUrl: "./media/video/room-transition/LOTUS_IN.mp4",
    fadeOutLeftVideoUrl: "./media/video/room-transition/LOTUS_OUT.mp4",
    fadeOutRightVideoUrl: "./media/video/room-transition/LOTUS_IN_REV.mp4",
    products: [
      {
        x: 0.52,
        y: .6,
        parallaxX: .001,
        parallaxY: 0,
        models: [
          {
            url: './models/Lotus.glb',
            focusPoint: new THREE.Vector3(0, 0.2, 0),
            key: 'MOD-BM00-0412-00',
            scale: 1,
            zoom: new THREE.Vector3(1.08, 0.7, 1.33), 
            animations: [
              {
                labelPosition: new THREE.Vector3(0, .4, 0),
                animationStartTime: 0,
                animationDuration: 300
              }
            ],
            colorBodyNames: [], 
            animatedMaterials: [
              {
                objectName: 'Cylinder1_2',
                // texture: './media/textures/pour_sprite_V2.png',
                texture: './media/textures/noise.png',
                x: 1,
                y: 1,
                color: "#c99000"
              },
              // {
              //   objectName: 'Cylinder_2',
              //   // texture: './media/textures/pour_sprite_V2.png',
              //   texture: './media/textures/noise.png',
              //   x: 1,
              //   y: 1,
              //   color: "#c99000"
              // }
            ]
          },
        ]
      }
    ]
  }
];