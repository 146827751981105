import React, {
  Component,
  CSSProperties,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import FilePlayer from "react-player/file";
import { createNoSubstitutionTemplateLiteral, isDebuggerStatement } from "typescript";

interface MyProps {
  onEnd?: () => void;
  playbackRate?: number;
  src: string;
  style?: CSSProperties;
  play?: boolean;
  className?: any;
  currentTime?: number;
};

export class Video2 extends Component<MyProps, {
  src: string
}> {

  player: any;
  ref = (player: any) => {
    this.player = player
  }

  constructor(props: MyProps) {
    super(props);
    this.state = {
      src: ''
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps: MyProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
      this.setState({ src: nextProps.src });
  }

  render() {
    return <ReactPlayer
    ref={this.ref}
    config={{
      // file: {
      //   attributes: {
      //     style: { height: "100%", width: "100%", objectFit: "cover" },
      //   },
      // },
    }}
    style={{
      ...this.props.style,
      position: "absolute",
      left: "0 !important",
      top: "0 !important",
      right: "0 !important",
      bottom: "0 !important",
      width: "initial !important",
      height: "initial !important",
      objectFit: "cover",
    }}
    // currenttime={
    //   props?.currentTime ?? 0 * (videoRef.current?.getDuration() ?? 0)
    // }
    className={this.props.className}
    muted
    playsinline
    playing={this.props.play}
    url={this.state.src}
    onEnded={() => this.props.onEnd && this.props.onEnd()}
    width="100%"
    height="100%"
    preload="auto"
  />; 
  }
}

export const Video = (props: {
  onEnd?: () => void;
  playbackRate?: number;
  src: string;
  style?: CSSProperties;
  play?: boolean;
  className?: any;
  currentTime?: number;
}) => {
  const videoRef = useRef<any>(null);

  const [src, setSrc] = useState<string>();

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  useEffect(() => {
    if(videoRef.current?.getDuration()) {
      if (props.currentTime) {
        videoRef.current?.seekTo(props.currentTime, "fraction");
      } 
      // else {
      //   console.log('Set to 0.5');
      //   videoRef.current?.seekTo(0.5, "fraction");
      // }
    }
  }, [props.currentTime]);

  return (
    <ReactPlayer
      ref={videoRef}
      config={{
        file: {
          attributes: {
            style: { height: "100%", width: "100%", objectFit: "cover" },
            preload: 'auto'
          },
        },
      }}
      style={{
        ...props.style,
        position: "absolute",
        left: "0 !important",
        top: "0 !important",
        right: "0 !important",
        bottom: "0 !important",
        width: "initial !important",
        height: "initial !important",
        objectFit: "cover",
      }}
      className={props.className}
      muted
      playsinline
      playing={props.play}
      url={src}
      onEnded={() => props.onEnd && props.onEnd()}
      width="100%"
      height="100%"
      preload="auto"
    />
  );
  // const [videoId, setVideoId] = useState("");
  // const [videoHtml, setVideoHtml] = useState("");
  // const videoParentRef = useRef<HTMLDivElement>(null);
  // let timeout: any;

  // useEffect(() => {
  //   setVideoHtml(`<video
  //       id="${videoId}"
  //       style="object-fit: cover; width: 100%; height: 100%;"
  //       autoPlay
  //       muted
  //       playsinline
  //       src="${props.src}#t=0.001"
  //     />`);
  // }, [props.play, props.src]);

  // useEffect(() => {
  //   videoParentRef.current?.children[0]?.addEventListener("ended", onVideoEnded);

  //   return () => {
  //     videoParentRef.current?.children[0]?.removeEventListener("ended", onVideoEnded);
  //   };
  // }, [videoHtml]);

  // useEffect(() => {
  //     let videoRef = videoParentRef.current?.children[0] as HTMLVideoElement;
  //     if (props.currentTime && videoRef && videoRef.duration) {
  //     videoRef.currentTime = props.currentTime * videoRef.duration;
  //   }
  //   console.log(props.currentTime);
  // }, [props.currentTime]);

  // const onVideoEnded = () => {
  //   console.log("ended");
  //   if (props.onEnd) props.onEnd();
  // };

  // return (
  //   <div
  //     className={props.className}
  //     style={props.style}
  //     ref={videoParentRef}
  //     dangerouslySetInnerHTML={{
  //       __html: videoHtml,
  //     }}
  //   />
  // );
};
