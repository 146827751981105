import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { HomePage } from './pages/home/home.page';
import { NewHomePage } from './pages/home/new-home.page';

function App() {
  return (
    <div className="App">
      <Suspense fallback={<p>Loading</p>}>
        <NewHomePage/>
      </Suspense>
    </div>
  );
}

export default App;
