import { CSSProperties, useState } from "react";
import { Dot } from "../../ClusterData";
import styles from "./room-ui.component.module.css";

export interface MyProps {
  title: string;
  roomIndex: number;
  amountOfRooms: number;
  transitioning: boolean;
  currentTime: number;
  products: Dot[];
  onNext: () => void;
  onPrevious: () => void;
  onGoToProduct: (selectedModelIndex: number) => void;
}

let titleStyle: any = {};
Object.assign(titleStyle, styles.title, styles.text);

export const RoomUIComponent = (props: MyProps) => {

  const widthIsBoss = window.innerWidth > window.innerHeight;

  const canvasOffsetX = widthIsBoss ? 0 : (window.innerHeight - window.innerWidth) / 2;
  const canvasOffsetY = !widthIsBoss ? 0 : (window.innerWidth - window.innerHeight) / 2;

  return (
    <>
      <div className={styles.container}>
        <img className="mt-4 white-svg" src="./media/images/NEW TAILS - 1.svg" width="20%"/>
        <div
          className={styles.bottomContainer}
          style={{
            transform: props.transitioning
              ? "translateY(50vh)"
              : "translateY(0)",
          }}
        >
          <p className={styles.text}>
            {props.roomIndex + 1}/{props.amountOfRooms}
          </p>
          <h1
            className={styles.text}
            style={{ opacity: props.transitioning ? "0" : "1" }}
          >
            {props.title}
          </h1>
          <div className="mt-2">
            <span className="arrows" style={{marginRight: 20}} onClick={() => props.onPrevious()}>
              <img src="/media/images/arrow.svg" />
            </span>
            <span className="arrows" onClick={() => props.onNext()}>
              <img
                style={{ transform: "rotate(180deg)" }}
                src="/media/images/arrow.svg"
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        {!props.transitioning && props.products.map((m, i) => (
          <div
            key={i}
            className="dot"
            onClick={() => props.onGoToProduct(i)}
            style={{
              transform: "translateX(" + -m.parallaxX * window.innerWidth * props.currentTime * 2 + "px) translateY( " + -m.parallaxY * window.innerHeight * props.currentTime * 2 + "px)",
              left: (widthIsBoss ? window.innerWidth : window.innerHeight) * m.x - canvasOffsetX - 30,
              top: (widthIsBoss ? window.innerWidth : window.innerHeight) * m.y - canvasOffsetY - 30,
            }}
          >
            <div className="inner-dot" />
            <div className="outer-dot" />
          </div>
        ))}
      </div>
    </>
  );
};
