import { Cluster } from "./ClusterData";

export const cacheVideoClustersToBlob = (clusters: Cluster[], onVideoCached: () => void) => {
    let blobs = {};

    for(let i = 0; i < clusters.length; i++)
    {
        fetch(clusters[i].parallaxVideoUrl).then(response => response.blob()).then(r => {
            (blobs as any)[clusters[i].parallaxVideoUrl] = window.URL.createObjectURL(r);
            onVideoCached();
        });

        fetch(clusters[i].fadeInLeftVideoUrl).then(response => response.blob()).then(r => {
            (blobs as any)[clusters[i].fadeInLeftVideoUrl] = window.URL.createObjectURL(r);
            onVideoCached();
        });

        fetch(clusters[i].fadeInRightVideoUrl).then(response => response.blob()).then(r => {
            (blobs as any)[clusters[i].fadeInRightVideoUrl] = window.URL.createObjectURL(r);
            onVideoCached();
        });

        fetch(clusters[i].fadeOutLeftVideoUrl).then(response => response.blob()).then(r => {
            (blobs as any)[clusters[i].fadeOutLeftVideoUrl] = window.URL.createObjectURL(r);
            onVideoCached();
        });

        fetch(clusters[i].fadeOutRightVideoUrl).then(response => response.blob()).then(r => {
            (blobs as any)[clusters[i].fadeOutRightVideoUrl] = window.URL.createObjectURL(r);
            onVideoCached();
        });
    }

    return blobs;
};

export const addGyroscopeEventHandler = (callback: (x: number, y: number) => void) =>  {
    window.addEventListener( 'deviceorientation', (e: any) => {
        callback(e.beta as number, e.gamma as number) ;
    });
};

export const registerGyroscope = (callback: (success: boolean) => void) => {
    (window.DeviceOrientationEvent as any).requestPermission().then( ( response: any ) => {
        if ( response == 'granted' ) {
            callback(true);
        } 
        else {
            callback(false);
        }
    } ).catch( ( error:any ) => {
        console.error(error);
        callback(false);
    } );
};