import { useCubeTexture, useGLTF, useTexture } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import {
  AnimatedMaterials,
  Animation,
  Model,
} from "../../../pages/home/ClusterData";
import { useSpring } from "@react-spring/core";
import tinycolor from "tinycolor2";

interface MyProps {
  productName: string;
  url: string;
  scale: number;
  color: string;
  swatchName: string;
  envMap: string;
  isInModelDetailModus: boolean;
  animations: Animation[];
  animatedMaterials?: AnimatedMaterials[];
  zoom: THREE.Vector3;
  colorBodyNames: string[];
  configData: Model;
}

let elapsed = 0;

export const ProductComponent = (props: MyProps) => {
  const [mixer, setMixer] = useState<THREE.AnimationMixer>();
  const [animating, setAnimating] = useState<boolean>(false);
  const three = useThree();

  const texture = useTexture(props.envMap);
  const [gltf, setGltf] = useState<any>();

  const { number } = useSpring({
    from: { number: 0, duration: 1500 },
    number: 1,
    loop: true,
  });

  useMemo(
    () =>
      new GLTFLoader().load(props.url, (model) => {
        setMixer(new THREE.AnimationMixer(model.scene));
        setGltf(model);
      }),
    [`./models/${props.productName}.glb`]
  );

  const reflectionMap = useTexture("./hdri_indoor.jpeg");
  const animatedTextures = useTexture(
    props.animatedMaterials?.map((x) => x.texture) || []
  );

  reflectionMap.mapping = THREE.EquirectangularReflectionMapping;
  reflectionMap.encoding = THREE.sRGBEncoding;

  useEffect(() => {
    animatedTextures.forEach((x, index) => {
      if (props.animatedMaterials != null) {
        x.wrapS = THREE.RepeatWrapping;
        x.wrapT = THREE.RepeatWrapping;
        x.minFilter = THREE.LinearFilter
        // x.repeat.x = 1;
        // x.repeat.y = 1;
        x.repeat.x = 1 / props.animatedMaterials[index].x;
        x.repeat.y = 1 / props.animatedMaterials[index].y;
        x.offset.x = index % 2 == 0 ? 0 :  1 / props.animatedMaterials[index].x;
        x.needsUpdate = true;
      }
    });
  }, []);

  useEffect(() => {
    // let lookDirection = new THREE.Vector3();
    // three.camera.getWorldDirection( lookDirection );
    // lookDirection.z = props.zoom;
    // three.camera.position.set(lookDirection.x, lookDirection.y, lookDirection.z);
    setGltf(null);
    (window as any).onAnimationClicked = () =>
      playAnimation(
        props.animations[0].animationStartTime,
        props.animations[0].animationDuration
      );
    three.camera.position.set(props.zoom.x, props.zoom.y, props.zoom.z);
  }, [props.productName]);

  // console.log(three.camera.position);

  useEffect(() => {
    if (gltf != null) {
      gltf.scene.traverse((c: any) => {
        if(c.name == "Cylinder_2") {
          c.visible = false;
        }
        if (props.animatedMaterials != null) {
          let animatedMaterial = props.animatedMaterials.find(
            (x) => x.objectName == c.name
          );
          let offset = 0;

          if (animatedMaterial != null && c.type == "Mesh") {
            let index = props.animatedMaterials.indexOf(animatedMaterial);
            c.material = new THREE.MeshBasicMaterial();
            c.material.color = new THREE.Color(animatedMaterial.color);
            c.material.map = animatedTextures[index];
            c.material.alphaMap = animatedTextures[index];
            c.material.transparent = true;
            c.renderOrder = -1;
            // c.material.side = THREE.DoubleSide;
            // c.material.alphaTest = .2;
            c.needsUpdate = true;
            c.material.reflectivity = 0;
            // c.geometry.computeVertexNormals();
            // c.material.depthTest = true;
            // c.material.depthWrite = true;
            // animatedTextures[index].offset.add(new THREE.Vector2(offset, 0));
            // offset += 1;
          }
        }

        if (c.type == "Mesh" && c.material) {
          if (
            props.colorBodyNames.findIndex((x) => c.name.indexOf(x) >= 0) >= 0
          ) {
            var configOverrideSwatch = props.configData.customColors?.find(
              (x) =>
                props.swatchName
                  .toLowerCase()
                  .indexOf(x.materialName.toLowerCase()) >= 0
            );
            if (configOverrideSwatch) {
              c.material.color = new THREE.Color(configOverrideSwatch.color);
              c.material.roughness = configOverrideSwatch.roughness;
              c.material.metallic = configOverrideSwatch.metallic;
            } else {
              c.material.color = new THREE.Color(props.color);
            }
          }

          var configOverride = props.configData.customColors?.find(
            (x) =>
              c.material.name
                .toLowerCase()
                .indexOf(x.materialName.toLowerCase()) >= 0
          );

          if (configOverride) {
            c.material.color = new THREE.Color(configOverride.color);
            c.material.roughness = configOverride.roughness;
            c.material.metallic = configOverride.metallic;
          }
        }
      });
    }
  }, [props.color, gltf]);

  const playAnimation = (startTime: number, duration: number) => {
    setAnimating(true);
    if (mixer) {
      let durations: number[] = [];

      gltf.animations.forEach((a: any) => {
        let track = THREE.AnimationUtils.subclip(
          a,
          "",
          startTime,
          startTime + duration
        );

        var clip = mixer?.clipAction(track);
        clip.loop = THREE.LoopOnce;
        clip.play();

        durations.push(track.duration);
      });

      setTimeout(
        () => setAnimating(false),
        1000 * Math.max.apply(Math, durations)
      );
    }
  };

  const [animationScale, setAnimationScale] = useState(0);

  useFrame((context, delta) => {
    mixer?.update(delta);
    setAnimationScale(number.get());
    animatedTextures.forEach((x) => { 
      x.offset.add(new THREE.Vector2(1 / -x.repeat.x, delta * -2));
      x.needsUpdate = true;
    });

    // elapsed += delta;
    // if (elapsed > 1) {
    //   animatedTextures.forEach((x) => {
    //     // x.offset.add(new THREE.Vector2(1 / x.repeat.x, 0));
    //     // console.log(x.offset);
    //     // x.needsUpdate = true;
    //   });
    //   elapsed = 0;
    // }
  });

  if (!gltf) return null;

  let materials: THREE.MeshStandardMaterial[] = [];

  gltf.scene.traverse((c: any) => {
    if (c.name.indexOf("FLOOR") >= 0) {
      c.visible = !props.isInModelDetailModus;
    }

    if (c.type == "Mesh" && c.material && c.name.indexOf("FLOOR") == -1) {
      c.material.envMap = reflectionMap;
      c.castShadow = true;
      c.receiveShadow = true;
    }

    let color = tinycolor(props.color).lighten(20).toHexString();

    if (c.type == "Mesh" && c.material) {
      materials.push(c.material);
    }
  });

  (window as any).object = gltf;
  (window as any).materials = materials;

  return (
    <>
      <primitive
        name={props.productName}
        object={gltf.scene}
        scale={[props.scale, props.scale, props.scale]}
        position={[0, 0, 0]}
        dispose={null}
      ></primitive>
      {!animating &&
        !props.isInModelDetailModus &&
        props.animations.map((a, i) => (
          <group key={i}>
            <mesh
              onPointerDown={() =>
                playAnimation(a.animationStartTime, a.animationDuration)
              }
              position={a.labelPosition}
            >
              <sphereGeometry
                attach="geometry"
                args={[animationScale * 0.03, 20, 20]}
              />
              <meshPhysicalMaterial
                attach="material"
                color="#FFF"
                transparent={true}
                emissive={new THREE.Color("#FFF")}
                opacity={1 * (1 - animationScale)}
              />
            </mesh>
            <mesh position={a.labelPosition}>
              <sphereGeometry attach="geometry" args={[0.01, 20, 20]} />
              <meshPhysicalMaterial
                attach="material"
                color="#FFF"
                emissive={new THREE.Color("#FFF")}
                opacity={1}
              />
            </mesh>
          </group>
        ))}
      ;
    </>
  );
};
