import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import * as THREE from "three";
import { EmailComponentUI } from "../email-ui/email.component-ui";

import styles from "./product-ui.component.module.css";

interface MyProps {
  productLineName: string;
  productKey: string;
  isInDetailsView: boolean;
  hasOtherProducts: boolean;
  hasOneAnimation: boolean;
  selectedColor?: number;
  onPreviousClicked: () => void;
  onNextClicked: () => void;
  onColorSwitched: (color: string, colorSwatch: string) => void;
  onGoBack: () => void;
  onDetailViewClicked: () => void;
  onAnimate: () => void;
  config: any;
}

export const ProductUIComponent = (props: MyProps) => {
  const [hasTouchedWindow, setHasTouchedWindow] = useState(false);
  const [selectedColor, setSelectedColor] = useState(props.selectedColor ?? 0);
  const [collapsed, setCollapsed] = useState(true);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [materials, setMaterials] = useState<THREE.MeshStandardMaterial[]>([]);

  useEffect(() => {
    window.addEventListener("pointerdown", (e) => {
      setHasTouchedWindow(true);
    });
  }, []);

  useEffect(() => {
    if (props.isInDetailsView) props.onDetailViewClicked();
  }, [props.productKey]);

  let config = props.config[selectedColor];
  let colorSwatches: any[] = [];

  props.config.map((c: any) => {
    if (colorSwatches.indexOf(c) === -1) {
      colorSwatches.push(c);
    }
  });

  useEffect(() => {
    if (colorSwatches.length > 0) {
      props.onColorSwitched(colorSwatches[selectedColor].rgb, colorSwatches[selectedColor].colorSwatch);
    }
  }, [selectedColor]);

  const canCollapse = window.innerWidth < 450;

  return (
    <>
      {canCollapse && (
        <div
          className={styles.collapseButton}
          onClick={() => setCollapsed(!collapsed)}
          style={{ pointerEvents: "all" }}
        >
          <span
            className="arrow"
            style={{ transform: collapsed ? "" : "rotateZ(315deg)" }}
          ></span>
        </div>
      )}
      { selectedColor >= 0 && <EmailComponentUI productName={props.config[selectedColor].itemName} show={showMoreInfo} onHide={() => setShowMoreInfo(false)}/> }
      <div
        style={{ opacity: hasTouchedWindow ? 0 : 1 }}
        className={styles.dragInfoBox}
      >
        <p className="text mb-0">Hold mouse and drag</p>
      </div>

      <div className={styles.topContainer}>
        <h6 className={styles.backBtn} onClick={() => props.onGoBack()}>
          <span className="arrow me-2" /> House overview
        </h6>
      </div>

      <img className="mt-4 white-svg" src="./media/images/NEW TAILS - 1.svg" width="20%" style={{position: 'fixed', top:0,  left: 'calc(50vw - 20% / 2)', zIndex: 9, pointerEvents: 'none', userSelect: 'none'}}/>
      <div
        className={styles.infoContainer}
        style={{
          transform:
            canCollapse && collapsed
              ? "translate(350px, -50px)"
              : "translate(0, -50px)",
          transition: "transform ease 1s",
        }}
      >
        {config != null && (
          <>
            {config["length"] && (
              <div className="d-flex flex-column align-items-start">
                <h5>DIMENSIONS</h5>
                <div
                  className="d-flex flex-row"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <h5>L {config["length"]}</h5>
                  <h5 className="mx-1">x</h5>
                  <h5>W {config["width"]}</h5>
                  <h5 className="mx-1">x</h5>
                  <h5>H {config["height"]}</h5>
                  <h5 className="mx-1">cm</h5>
                </div>
              </div>
            )}
            {config["volume"] && (
              <div className="d-flex flex-column align-items-start mt-4">
                <h5>VOLUME</h5>
                <h5>{config["volume"]} ml</h5>
              </div>
            )}
            <div className="d-flex flex-column align-items-start mt-4">
              <h5>CODE</h5>
              <h5>{colorSwatches[selectedColor].itemCode}</h5>
            </div>
            <div className="d-flex flex-row align-items-start mt-4">
              {colorSwatches.map((c: any, i: number) => {
                let isSelected = selectedColor == i;
                return (
                  <div
                    onClick={() => setSelectedColor(i)}
                    className="color-swatch pointer me-2"
                    style={{
                      width: isSelected ? 70 : 20,
                      backgroundColor: c.rgb,
                    }}
                  />
                );
              })}
            </div>
            <div className="d-flex flex-row align-items-start mt-4">
              {/* <img src={`${colorSwatches[selectedColor]['colorSwatch']}`}/> */}
            </div>
            <div
              className="pointer d-flex align-items-center justify-content-start mt-4 submit-button"
              style={{ width: 200, border: 'none' }}
              onClick={e => setShowMoreInfo(true)}
            >
              <h5>Request more info</h5>
              {/* <span
                className="arrow ms-2"
                style={{ transform: "rotate(-45deg)" }}
              /> */}
            </div>
          </>
        )}
        {/* <div
          className="d-flex flex-column justify-content-center mt-3 mb-5"
          style={{ pointerEvents: "all", overflowY: "auto" }}
        >
          {(window as any).materials?.map((m: THREE.MeshStandardMaterial) => (
            <div>
              <h5> {m.name} </h5>
              <input
                type="color"
                value={"#" + m.color.getHexString()}
                width="50px"
                onChange={(e) => (m.color = new THREE.Color(e.target.value))}
              />
              <br />
              M
              <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={m.metalness}
                onChange={(e) => (m.metalness = parseFloat(e.target.value))}
              />{" "}
              {m.metalness}
              <br />
              R
              <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={m.roughness}
                onChange={(e) => (m.roughness = parseFloat(e.target.value))}
              />{" "}
              {m.roughness}
            </div>
          ))}
        </div> */}
      </div>
      <div className={styles.bottomContainer}>
        <div>
          {props.hasOneAnimation && false && (
            <img
              style={{ opacity: props.isInDetailsView ? 0.2 : 1 }}
              className="mb-3 me-2 pointer"
              width="40"
              src="/media/images/play-icon.svg"
              onClick={() => props.onAnimate()}
            />
          )}
          <img
            className="mb-3 pointer"
            width="40"
            src="/media/images/3d-icon.svg"
            onClick={() => props.onDetailViewClicked()}
          />
        </div>
        <h5 className="text whitespace mb-1">{props.productLineName}</h5>
        <div className="d-flex flex-row justify-content-center align-items-center">
          {props.hasOtherProducts && (
            <div
              className="arrows mx-3"
              onClick={() => props.onPreviousClicked()}
            >
              <img src="/media/images/arrow.svg" />
            </div>
          )}
          <h2 className="text">
            {config ? config["itemName"] : `Unkown key ${props.productKey}`}
          </h2>
          {props.hasOtherProducts && (
            <div className="arrows mx-3" onClick={() => props.onNextClicked()}>
              <img
                style={{ transform: "rotate(180deg)" }}
                src="/media/images/arrow.svg"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
