import React, { useEffect, useRef, useState } from "react";
import { Video } from "../video/video";

export interface MyProps {
  // videoUrls: string[];
  videoUrl: string;
  onEnd: () => void;
}

const TransitionVideo = (props: { delay: number; url: string, onEnd: () => void }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    let timeout: any;

  useEffect(() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
          setIsPlaying(true);
          props.onEnd();
      }, props.delay);
  }, []);

  return (
    <Video 
      play={isPlaying}
      playbackRate={2}
      onEnd={() => {
        setIsPlaying(false);
      }}
      src={props.url}
      style={{
          zIndex: isPlaying ? 999 : 99,
          opacity: isPlaying ? 1 : 0,//!isPlaying ? 0 : (ended ? 0 : 0.5),
          transition: 'opacity ease 1s',
          width: "100vw",
          height: "100vh",
          position: "fixed",
          userSelect: "none",
          pointerEvents: "none"
        }}
    />
    // <video
    //   style={{
    //     zIndex: isPlaying && !ended ? 10 : 0,
    //     opacity: isPlaying && !ended ? 1 : 0,//!isPlaying ? 0 : (ended ? 0 : 0.5),
    //     transition: 'opacity ease 1s',
    //     width: "100vw",
    //     height: "100vh",
    //     objectFit: "cover",
    //     position: "fixed",
    //     userSelect: "none",
    //     pointerEvents: "none",
    //   }}
    //   muted
    //   ref={videoRef}
    //   playsInline
    //   src={props.url}
    //   onEnded={() => onVideoEnded()}
    // />
  )
};

let amountOfVideoPlayed = 0;

export const VideoIntro = (props: MyProps) => {

  // const onTransitionEnded = () => {
  //     let newIndex = currentVideoIndex + 1;

  //     if(newIndex < props.videoUrls.length) {
  //         setCurrentVideoIndex(newIndex);
  //         videoRef.current?.play();
  //     }
  //     else {
  //         props.onEnd();
  //     }
  // };

  // useEffect(() => {
  //     if(videoRef.current) {
  //         videoRef.current.playbackRate = 2.0;
  //     }
  // }, [videoRef]);

  // useEffect(() => {}, [props.videoUrls]);

  // const onVideoEnd = () => {
  //   amountOfVideoPlayed++;
  //   if(amountOfVideoPlayed >= props.videoUrls.length) {
  //       props.onEnd();
  //   }
  // };

  // let videoUrls = [... props.videoUrls];
  // videoUrls.push(videoUrls.shift() as any);

  return (
    <>
      {/* {videoUrls.map((v, i) => (
        <TransitionVideo key={i} url={v} delay={(i + 1) * 700} onEnd={() => onVideoEnd()} />
      ))} */}

      <Video src={props.videoUrl} onEnd={() => props.onEnd()} play />
    </>
  );

  // src={props.videoUrls[currentVideoIndex]}
  // onEnded={() => onTransitionEnded()}
};
