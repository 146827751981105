import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useSpring } from "react-spring";
import { RoomUIComponent } from "./components/room-ui/room-ui.component";

import "./new-home.page.css";

import { Cluster, clusters } from "./ClusterData";
import {
  addGyroscopeEventHandler,
  cacheVideoClustersToBlob,
  registerGyroscope,
} from "./Utilities";
import { SceneComponent } from "../../components/threejs/scene/scene.component";
import { OrbitControls, DeviceOrientationControls } from "@react-three/drei";
import { ProductComponent } from "../../components/threejs/Product/product.component";
import { ProductUIComponent } from "./components/product-ui/product-ui.component";
import { ThreeSixtyComponent } from "../../components/threejs/threesixty/threesixty.component";
import * as THREE from "three";
import { SkyboxComponent } from "../../components/threejs/skybox/skybox.component";
import { VideoIntro } from "./components/video-intro/video-intro";
import { Video } from "./components/video/video";

export const NewHomePage = () => {
  // const videoRef = useRef<HTMLVideoElement>(null);

  const parallaxVideoRef = useRef<HTMLVideoElement>(null);
  const videoOutRef = useRef<HTMLVideoElement>(null);
  const videoInRef = useRef<HTMLVideoElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);
  const [selectedCluster, setSelectedCluster] = useState(0);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(
    clusters[selectedCluster].parallaxVideoUrl
  );
  const [currentTransitionVideoUrl, setCurrentTransitionVideoUrl] =
    useState("");

  const [isTransitioning, setIsTransitioning] = useState(true);
  const [isInModelDetailModus, setIsInModelDetailModus] = useState(false);
  const [transitionLeft, setTransitionLeft] = useState(false);
  const [firstTransitionDone, setFirstTransitionDone] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);
  const [videoBlobs, setVideoBlobs] = useState({});
  const [currentTimeValue, setCurrentTimeValue] = useState(0);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState(-1);
  const [currentSelectedModel, setCurrentSelectedModel] = useState(-1);
  const [currentSelectedVariant, setCurrentSelectedVariant] = useState(0);

  const [currentSelectedColor, setColor] = useState("");
  const [currentSelectedColorSwatch, setCurrentSelectedColorSwatch] =
    useState("");

  const [introRunning, setIntroRunning] = useState(false);
  const [gyroEnabled, setGyroEnabled] = useState(true);
  const [gyroAvailable, setGyroAvailable] = useState(true);
  const [gyroscope, setGyroscope] = useState({ x: 0, y: 0 });
  const [config, setConfig] = useState<any>();

  const orbitControlsRef = useRef<any>();

  const [{ currentTime }, setCurrentTime] = useSpring(() => ({
    currentTime: 0,
    config: { mass: 10, tension: 6000, friction: 500, precision: 0.0000001 },
    onChange: (val) => {
      // window.requestAnimationFrame((d) => {
      if (val.value.currentTime) {
        // if (current) {
        if (parallaxVideoRef.current) {
          let current =
            parallaxVideoRef.current.duration * val.value.currentTime;
          if (current) parallaxVideoRef.current.currentTime = current;
        }
        setCurrentTimeValue(val.value.currentTime);
        // }
      }
      // });
    },
  }));

  const registerGyropscope = () => {
    registerGyroscope((success) => {
      setGyroEnabled(success);
    });
    addGyroscopeEventHandler((x, y) => {
      if (!isTransitioning) {
        setCurrentTime({ currentTime: clamp(x / 60 - 60, 0, 1) });
      }
    });
    setGyroEnabled(true);
  };

  // useEffect(() => {
  //   if(videoRef.current) {
  //     videoRef.current.load();
  //     videoRef.current.pause();
  //   }
  // }, [videoRef])

  useEffect(() => {
    let cachedBlobs = 0;
    let blobs = cacheVideoClustersToBlob(clusters, () => {
      cachedBlobs++;
      setLoadingCount(cachedBlobs);
      if (cachedBlobs == clusters.length * 5) {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    });

    setVideoBlobs(blobs);
    updateVideoParallax(window.innerWidth / 2);

    fetch("./data/temp_json.json")
      .then((r) => r.json())
      .then((r: any[]) => {
        let transformedData = r
          // .map((x) => {
          //   return {
          //     itemName: x["A"],
          //     itemCode: x["B"],
          //     size: x["C"],
          //     colorSwatch: x["D"],
          //     width: x["E"],
          //     length: x["F"],
          //     height: x["G"],
          //     volume: x["H"],
          //     volumeUnit: x["I"],
          //     volume2: x["J"],
          //     volume2Unit: x["K"],
          //     color: x["L"],
          //     rgb: x["M"],
          //   };
          // })
          .map((r) => {
            r.rgb = r.cssColourCode;
            return r;
          })
          .filter((x) => x.colorSwatch.indexOf("International") >= 0);

        // let newData = transformedData.filter(
        //   (x) => x["itemName"].toLowerCase() == props.productName.toLowerCase()
        // );
        setConfig(transformedData);

        const queryParams = new URLSearchParams(window.location.search);
        const productCode = queryParams.get("product");

        let clusterIndex = -1;
        let productIndex = -1;
        let modelIndex = -1;
        let variantIndex = -1;

        for (let c = 0; c < clusters.length; c++) {
          for (let p = 0; p < clusters[c].products.length; p++) {
            for (let m = 0; m < clusters[c].products[p].models.length; m++) {
              const variants = transformedData.filter(
                (x: any) =>
                  x["itemCode"].indexOf(
                    clusters[c].products[p].models[m].key
                  ) >= 0
              );
              const matchingIndex = variants.findIndex(
                (x: any) => x.itemCode == productCode
              );

              if (matchingIndex != null && matchingIndex >= 0) {
                clusterIndex = c;
                productIndex = p;
                modelIndex = m;
                variantIndex = matchingIndex;
              }
            }
          }
        }

        if (productCode && clusterIndex >= 0) {
          setIntroRunning(false);
          setIsTransitioning(false);
          setSelectedCluster(clusterIndex);
          setCurrentSelectedProduct(productIndex);
          setCurrentSelectedModel(modelIndex);
          setCurrentSelectedVariant(variantIndex);
        } else {
          setIntroRunning(true);
        }
      });
  }, []);

  useEffect(() => {
    if (!isTransitioning) {
      window.addEventListener("mousemove", onMouseMoved);
      document.ontouchmove = function (event) {
        event.preventDefault();
      };
      // videoRef.current?.play();
      // videoRef.current?.pause();

      return () => {
        window.removeEventListener("mousemove", onMouseMoved);
      };
    }
  }, [isTransitioning]);

  useEffect(() => {
    if (!introRunning) {
      setGyroEnabled(false);
      reloadParallaxVideo();
      setMousePosition(window.innerWidth / 2);
      setCurrentTime({ currentTime: 0.5 });
    }
  }, [introRunning]);

  useEffect(() => {
    reloadParallaxVideo();
  }, [selectedCluster]);

  const getNextIndex = (increment: boolean, current: number, count: number) => {
    return (((current + (increment ? 1 : -1)) % count) + count) % count;
  };

  const onTransitionEnded = () => {
    if (firstTransitionDone) {
      setIsTransitioning(false);
      setFirstTransitionDone(false);
    } else {
      setFirstTransitionDone(true);
      videoInRef.current?.play();
    }
  };

  const onTransitionVideoLoaded = () => {
    setFirstTransitionDone(false);
    setIsTransitioning(true);

    if (!firstTransitionDone) {
      console.log("tran done");
      let nextIndex = getNextIndex(
        !transitionLeft,
        selectedCluster,
        clusters.length
      );
      setSelectedCluster(nextIndex);
    }
  };

  const reloadParallaxVideo = () => {
    if (parallaxVideoRef.current) {
      parallaxVideoRef.current.src = clusters[selectedCluster].parallaxVideoUrl;
      parallaxVideoRef.current.load();
    }
  };

  const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
  };

  const onMouseMoved = (e: MouseEvent) => {
    if (isTransitioning) return;
    setMousePosition(e.clientX);
  };

  const goToNextRoom = () => {
    if (!isTransitioning) {
      setCurrentTime({ currentTime: 0.5 });
      setTransitionLeft(false);
      let nextIndex = getNextIndex(true, selectedCluster, clusters.length);

      if (videoOutRef.current) {
        videoOutRef.current.src =
          clusters[selectedCluster].fadeOutRightVideoUrl;
        videoOutRef.current.play();
      }

      if (videoInRef.current) {
        videoInRef.current.src = clusters[nextIndex].fadeInLeftVideoUrl;
        videoInRef.current.load();
      }
    }
  };

  const goToPreviousRoom = () => {
    if (!isTransitioning) {
      setCurrentTime({ currentTime: 0.5 });
      setTransitionLeft(true);
      let nextIndex = getNextIndex(false, selectedCluster, clusters.length);

      if (videoOutRef.current) {
        videoOutRef.current.src = clusters[selectedCluster].fadeOutLeftVideoUrl;
        videoOutRef.current.play();
      }

      if (videoInRef.current) {
        videoInRef.current.src = clusters[nextIndex].fadeInRightVideoUrl;
        videoInRef.current.load();
      }
    }
  };

  const goToProduct = (
    selectedProductIndex: number,
    selectedModelIndex: number = 0
  ) => {
    setCurrentSelectedProduct(selectedProductIndex);
    setCurrentSelectedModel(selectedModelIndex);
  };

  const selectNextModel = () => {
    let nextIndex = getNextIndex(
      true,
      currentSelectedModel,
      clusters[selectedCluster].products[currentSelectedProduct].models.length
    );
    setCurrentSelectedModel(nextIndex);
  };

  const selectPreviousModel = () => {
    let nextIndex = getNextIndex(
      false,
      currentSelectedModel,
      clusters[selectedCluster].products[currentSelectedProduct].models.length
    );
    setCurrentSelectedModel(nextIndex);
  };

  const updateVideoParallax = (mouseX: number) => {
    let hotspotSizeInPercent = 0.7;

    let mouseNormalizedPosition = mouseX / window.innerWidth;
    let hotspotNormalized = 1 / 2 - hotspotSizeInPercent / 2;
    let hotspotNormalizedMousePosition =
      mouseNormalizedPosition - hotspotNormalized;
    let normalizedPosition =
      hotspotNormalizedMousePosition / hotspotSizeInPercent;

    setCurrentTime({ currentTime: clamp(normalizedPosition, 0, 1) });
  };

  useEffect(() => {
    if (!isTransitioning) {
      updateVideoParallax(mousePosition);
    }
  }, [mousePosition]);

  if (isLoading) {
    return (
      <div
        className="loading-screen d-flex flex-column justify-content-between align-items-center"
        style={{ opacity: isLoading ? 1 : 0 }}
      >
        <img
          className="mt-4 white-svg"
          src="./media/images/NEW TAILS - 1.svg"
          width="30%"
        />
        <h2 className="mb-2">
          Welcome to New Tails by MODERNA.
          <br />
          A magical house where we showcase our product novelties.
          <br />
          <br />
          Join us in this magnificent moment and,
          <br />
          let yourself be enchanted about whats to come…
        </h2>
        <br />
        <br />
        <h2 className="mb-0">
          {Math.round((loadingCount / (clusters.length * 5)) * 100) + "%"}
        </h2>
        <div
          className="loading-bar"
          style={{ width: "30vw", height: 5, zIndex: 9 }}
        >
          <div
            className="loading-bar-handle"
            style={{
              height: "100%",
              width: (loadingCount / (clusters.length * 5)) * 100 + "%",
              backgroundColor: "white",
              borderRadius: 5,
              transition: "width ease 1s",
            }}
          ></div>
        </div>
        <img
          src="./media/images/MODERNA with baseline white.svg"
          width="30%"
          height="30%"
          style={{ marginTop: -120 }}
        />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          opacity: introRunning ? 1 : 0,
          transition: "opacity ease 1s",
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        <VideoIntro
          videoUrl="./media/video/rooms_rotation.mp4"
          onEnd={() => {
            console.log("intro ended");
            setIntroRunning(false);
            setIsTransitioning(false);
            setCurrentTime({ currentTime: 0.5 });
          }}
        />
      </div>
      {selectedCluster >= 0 &&
        currentSelectedProduct >= 0 &&
        currentSelectedModel >= 0 && (
          <>
            <ProductUIComponent
              productKey={
                clusters[selectedCluster].products[currentSelectedProduct]
                  .models[currentSelectedModel].key
              }
              productLineName={clusters[selectedCluster].title}
              isInDetailsView={isInModelDetailModus}
              hasOneAnimation={
                clusters[selectedCluster].products[currentSelectedProduct]
                  .models[currentSelectedModel].animations.length == 1
              }
              onColorSwitched={(c, n) => {
                setColor(c);
                setCurrentSelectedColorSwatch(n);
              }}
              onNextClicked={() => selectNextModel()}
              onPreviousClicked={() => selectPreviousModel()}
              onGoBack={() => {
                setCurrentSelectedModel(-1);
                setCurrentSelectedProduct(-1);
                setCurrentSelectedVariant(0);
                reloadParallaxVideo();
              }}
              onDetailViewClicked={() =>
                setIsInModelDetailModus(!isInModelDetailModus)
              }
              onAnimate={() => (window as any).onAnimationClicked()}
              hasOtherProducts={
                clusters[selectedCluster].products[currentSelectedProduct]
                  .models.length > 1
              }
              config={config.filter(
                (x: any) =>
                  x["itemCode"].indexOf(
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].key
                  ) >= 0
              )}
              selectedColor={currentSelectedVariant}
            />
            <SceneComponent>
              <OrbitControls
                ref={orbitControlsRef}
                position={[-0.1, 0, -0.3]}
                enablePan={false}
                maxDistance={4}
                maxPolarAngle={
                  isInModelDetailModus
                    ? Math.PI * 2
                    : Math.PI / 2 - Math.PI * 0.03
                }
                target={
                  clusters[selectedCluster].products[currentSelectedProduct]
                    .models[currentSelectedModel].focusPoint
                }
              />
              <Suspense fallback={null}>
                {!isInModelDetailModus && (
                  <ThreeSixtyComponent url={clusters[selectedCluster].bg} />
                )}
                {isInModelDetailModus && (
                  <SkyboxComponent color={currentSelectedColor} />
                )}
                <ProductComponent
                  productName={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].key
                  }
                  url={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].url
                  }
                  scale={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].scale
                  }
                  animations={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].animations
                  }
                  animatedMaterials={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].animatedMaterials
                  }
                  envMap={clusters[selectedCluster].bg}
                  color={currentSelectedColor}
                  isInModelDetailModus={isInModelDetailModus}
                  zoom={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].zoom
                  }
                  colorBodyNames={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel].colorBodyNames
                  }
                  swatchName={currentSelectedColorSwatch}
                  configData={
                    clusters[selectedCluster].products[currentSelectedProduct]
                      .models[currentSelectedModel]
                  }
                />
              </Suspense>
            </SceneComponent>
          </>
        )}
      {currentSelectedModel < 0 && (
        <>
          <RoomUIComponent
            {...clusters[selectedCluster]}
            roomIndex={selectedCluster}
            amountOfRooms={clusters.length}
            currentTime={currentTimeValue}
            transitioning={isTransitioning}
            onNext={() => goToNextRoom()}
            onPrevious={() => goToPreviousRoom()}
            onGoToProduct={(selectedModelIndex) =>
              goToProduct(selectedModelIndex)
            }
          />

          {/* {isTransitioning && <Video
            // style={{
            //   opacity: isTransitioning ? 1 : 0,
            // }}
            className="videoPlayerTransition"
            src={(videoBlobs as any)[currentTransitionVideoUrl]}
            onEnd={() => onTransitionEnded()}
            play={isTransitioning}
          />} */}

          {/* <Video
            style={{
              
              opacity: !isTransitioning ? 1 : 0,
            }}
            className="videoPlayer"
            src={(videoBlobs as any)[currentVideoUrl]}
            currentTime={currentTimeValue}
            
          /> */}
        </>
      )}

      <video
        style={{ opacity: isTransitioning && !firstTransitionDone ? 1 : 0 }}
        ref={videoOutRef}
        preload="auto"
        playsInline
        muted
        className="videoPlayerRaw"
        onEnded={() => onTransitionEnded()}
        onLoadedData={() => onTransitionVideoLoaded()}
      />

      <video
        style={{ opacity: isTransitioning && firstTransitionDone ? 1 : 0 }}
        ref={videoInRef}
        preload="auto"
        playsInline
        muted
        className="videoPlayerRaw"
        onEnded={() => onTransitionEnded()}
      />
      <video
        style={{
          opacity: !isTransitioning ? 1 : 0,
        }}
        ref={parallaxVideoRef}
        src={currentVideoUrl}
        preload="auto"
        playsInline
        muted
        className="videoPlayerRaw"
      />
    </>
  );
};
